<template>
<div class="my-4">
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <b-form inline>
          <b-form-select class="mr-4" v-model="countrySelected" :options="countryOptions" size="lg" style="font-weight: 900"></b-form-select>
          compare to: <b-form-select v-model="countrySelected2" :options="countryOptions2" class="mx-2"></b-form-select>
        </b-form>
      </b-col>
    </b-row>
    <!--
    <b-row class="mb-3" v-if="initiativesSummary.length > 0">
      <b-col>
        <h3>Summary</h3>
      </b-col>
    </b-row>
    <b-row class="mb-5" v-if="initiativesSummary.length > 0">
      <b-col md="12" lg="2" xl="7">
        <strong>Headline</strong>
      </b-col>
      <b-col md="12" lg="2" xl="1">
        <strong>Impulse</strong>
      </b-col>
      <b-col md="12" lg="2" xl="1">
        <strong>Coverage</strong>
      </b-col>
      <b-col md="12" lg="2" xl="1">
        <strong>Direction</strong>
      </b-col>
      <b-col md="12" lg="2" xl="1">
        <strong>Horizon</strong>
      </b-col>
      <b-col md="12" lg="2" xl="1">
        <strong>Composite</strong>
      </b-col>
    </b-row>
    <div v-if="initiativesSummary.length > 0">
      <div class="mb-5" v-for="initiative in initiativesSummary" :key="'summary-' + initiative.id">
        <impactpolicy-summary :item="initiative" />
      </div>
    </div>
    -->
    <b-row class="mb-4">
      <b-col lg="2" class="py-3 px-0" style="background: white; height: 60vh; overflow-y: auto;  overflow-x: auto;">
        <div>
            <div class="mb-5" v-for="(row, index) in initiativesByArea" :key="'topic-' + index">
              <div class="px-3 py-3" :id="'area'+row.area.id" @mouseover="findInitiatives(row.area)">
                {{row.area.name}}
              </div>
            </div>
        </div>
      </b-col>
      <b-col lg="5" v-if="showInitiatives" class="py-3 px-0" style="background: #e6f9ff; height: 60vh; overflow-y: auto;  overflow-x: auto;">
          <h5 class="px-3">{{ area.name }}</h5>
          <div class="px-3 py-3" v-for="(initiative, index) in initiativesShow" :key="'initiative-' + index" :id="'initiative'+initiative.id" @mouseover="findInitiative(initiative)">
            <div>
              <router-link :to="{ name: 'ImpactPoliciesInitiative', params: { id: initiative.id }}">
                <div class="mb-2"><strong>{{initiative.name}}</strong></div>
                <div v-if="initiative.impactpolicy_id > 0">
                 <policy-initiative-details :item="initiative" />
                </div>
              </router-link>
            </div>
          </div>
      </b-col>
      <b-col lg="5" v-if="initiative" class="py-3" style="background: white; height: 60vh; overflow-y: auto;  overflow-x: auto;">
          <h5><router-link :to="{ name: 'ImpactPoliciesInitiative', params: { id: initiative.id }}">{{ initiative.name }}</router-link></h5>
          <div><b-link :to="{ name: 'ImpactPoliciesCountry', params: { country: initiative.country } }">{{ initiative.country }}</b-link></div>
          <div v-if="initiative.impactexperts.length > 0">
          <div class="mt-4"><strong>Experts</strong></div>
          <div v-for="expert in initiative.impactexperts" :key="'expert'+expert.id">
            <div v-if="$config.BUILD === 'full'">
              <router-link :to="{ name: 'Expert', params: { id: expert.id }}">{{expert.name}}</router-link>
            </div>
            <div v-else>{{expert.name}}</div>
          </div>
          </div>
          <div class="my-4"><strong>{{ initiative.headline }}</strong></div>
          <div v-html="initiative.description"></div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
// import ImpactpolicyAccordion from '@/components/ImpactpolicyAccordion'
// import ImpactpolicySummary from '@/components/ImpactpolicySummary'
import PolicyInitiativeDetails from '@/components/PolicyInitiativeDetails'

// create delay function
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export default {
  name: 'CountryPolicies',
  components: {
    // ImpactpolicyAccordion
    // ImpactpolicySummary
    PolicyInitiativeDetails
  },
  computed: {
    countryOptions: function () {
      const all = this.$store.state.initiativesPolicies
      let results = all.map(x => x.country)
      results = _.uniq(results)
      results.sort()
      return results
    },
    countryOptions2: function () {
      const all = this.$store.state.initiativesPolicies
      let results = all.map(x => x.country)
      results = _.uniq(results)
      _.pull(results, this.countrySelected)
      results.sort()
      return results
    },
    initiatives: function () {
      const country = this.$route.params.country
      const results = []
      const all = this.$store.state.initiativesPolicies
      all.forEach(x => {
        if (x.country === country) {
          results.push(x)
        }
      })
      return results
    },
    initiativesByArea: function () {
      const results = []
      const all = this.initiatives
      this.areas.forEach(area => {
        let row = {}
        row = {
          area: area,
          area_id: area.id,
          initiatives: []
        }
        all.forEach(initiative => {
          if (initiative.impactpolicy.taxonomy.length > 0) {
            initiative.impactpolicy.taxonomy.forEach(t => {
              if (t.id === area.id) {
                row.initiatives.push(initiative)
              }
            })
          }
        })
        results.push(row)
      })
      return results
    },
    initiativesSummary: function () {
      const all = this.initiatives
      const ids = [4]
      const results = []
      all.forEach(policy => {
        if (ids.find(id => policy.impactpolicy_id === id)) {
          results.push(policy)
        }
      })
      return results
    },
    areas: function () {
      const all = this.initiatives
      let results = []
      all.forEach(i => {
        if (i.impactpolicy.taxonomy.length > 0) {
          i.impactpolicy.taxonomy.forEach(taxonomy => {
            results.push(taxonomy)
          })
        }
      })
      results = _.uniqBy(results, 'id')
      results.sort()
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "Climate Policy and Regulatory Dashboard: " + this.$route.params.country
    this.$stat.log({ page: 'impactpolicies list', action: 'open impactpolicies list' })
    if (this.$route.params.country) {
      this.countrySelected = this.$route.params.country
      window.localStorage.setItem('frmCloudCountrySelected', this.countrySelected)
    } else if (window.localStorage.getItem('frmCloudCountrySelected')) {
      this.countrySelected = window.localStorage.getItem('frmCloudCountrySelected')
    } else {
      this.countrySelected = this.countryOptions[0]
    }
    await delay(250)
    this.findInitiatives(this.areas[0])
  },
  data () {
    const data = {
      area: null,
      countrySelected: null,
      countrySelected2: '',
      initiative: null,
      initiativesShow: [],
      showInitiatives: false
    }
    return data
  },
  methods: {
    findInitiative: function (initiative) {
      if (this.initiative) {
        document.getElementById('initiative' + this.initiative.id).setAttribute('style', 'background-color: #e6f9ff;')
      }
      this.initiative = initiative
      document.getElementById('initiative' + this.initiative.id).setAttribute('style', 'background-color: #aceaff;')
    },
    findInitiatives: function (area) {
      if (this.area) {
        document.getElementById('area' + this.area.id).setAttribute('style', 'background-color: white;')
      }
      if (this.initiative) {
        document.getElementById('initiative' + this.initiative.id).setAttribute('style', 'background-color: #e6f9ff;')
      }
      this.initiative = null
      this.showInitiatives = false
      this.area = area
      const initiatives = _.find(this.initiativesByArea, { area_id: area.id })
      if (initiatives) {
        this.initiativesShow = initiatives.initiatives
      } else {
        this.initiativesShow = []
      }
      document.getElementById('area' + this.area.id).setAttribute('style', 'background-color: #aceaff;')
      this.showInitiatives = true
    }
  },
  watch: {
    '$route.params.country': function () {
      this.countrySelected = this.$route.params.country
      document.title = "Climate Policy Dashboard: " + this.$route.params.country
    },
    'countrySelected': async function () {
      window.localStorage.setItem('frmCloudCountrySelected', this.countrySelected)
      if (this.countrySelected !== this.$route.params.country) {
        this.$router.push({ name: 'ImpactPoliciesCountry', params: { country: this.countrySelected } })
        this.initiative = null
        this.showInitiatives = false
        await delay(250)
        this.findInitiatives(this.areas[0])
      }
    },
    'countrySelected2': function () {
      this.$router.push({ name: 'ImpactPoliciesCountryComparison', params: { country1: this.countrySelected, country2: this.countrySelected2 } })
    }
  }
}
</script>

<style>
</style>
