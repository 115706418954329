<template>
<div>
  <b-icon-info-circle @click="showModal" style="cursor: pointer;" />
  <b-modal :id="uuid" centered title="Help" ok-only>
    <p class="my-4">{{ message }}</p>
  </b-modal>
</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { BIconInfoCircle } from 'bootstrap-vue'

export default {
  name: 'InfoCircle',
  props: ['message'],
  components: {
    BIconInfoCircle
  },
  data () {
    return {
      uuid: null
    }
  },
  created: function () {
    this.uuid = uuidv4()
  },
  methods: {
    showModal () {
      this.$bvModal.show(this.uuid)
    }
  }
}
</script>
