<template>
<div>
    <b-alert show>
      <b-table-simple hover small style="max-width: 500px">
        <b-tbody>
          <div v-for="field in schema.fields" :key="schema.id + '-' + field.name">
            <div v-if="!field.skip">
              <b-tr v-if="field.type === 'number'">
                <b-th class="text-right" style="width: 250px">{{  field.label }}<info-circle v-if="field.help" :message="field.help" /></b-th>
                <td>
                  <span v-if="item.policydata[field.name]">
                    <span v-if="field.formatter">{{field.formatter(item.policydata)}}</span>
                    <span v-else>{{item.policydata[field.name]}}</span>
                  </span>
                  <span v-else>Not available</span>
                </td>
              </b-tr>
              <b-tr v-if="field.type === 'boolean'">
                <b-th class="text-right" style="width: 250px">{{  field.label }}<info-circle v-if="field.help" :message="field.help" /></b-th>
                <b-td v-if="item.policydata[field.name] === 'true'">Yes</b-td>
                <b-td v-else>No</b-td>
              </b-tr>
              <b-tr v-if="field.type === 'select'">
                <b-th class="text-right" style="width: 250px">{{  field.label }}<info-circle v-if="field.help" :message="field.help" /></b-th>
                <b-td v-if="field.formatter">{{field.formatter(item.policydata)}}</b-td>
                <b-td v-else>{{item.policydata[field.name]}}</b-td>
              </b-tr>
              <b-tr v-if="field.type === 'checkbox' && item.policydata[field.name]">
                <b-th class="text-right" style="width: 250px">{{  field.label }}<info-circle v-if="field.help" :message="field.help" /></b-th>
                <b-td v-if="item.policydata[field.name].length > 0"><span v-for="item in item.policydata[field.name]" :key="'coverage' + item">{{item}}, </span></b-td>
                <b-td v-else>No data</b-td>
              </b-tr>
            </div>
          </div>
        </b-tbody>
      </b-table-simple>
    </b-alert>
</div>
</template>

<script>
import policysets from '@/libs/policysets'
import InfoCircle from '@/components/InfoCircle.vue'

export default {
  name: 'PolicyInitiativeDetails',
  props: ['item'],
  components: {
    InfoCircle
  },
  computed: {
    schema () {
      // find record from policysets based on item.impactpolicy_id
      const record = policysets.find(item => item.id === this.item.impactpolicy_id)
      return record
    }
  },
  data () {
    const data = {
    }
    return data
  }
}
</script>

<style scoped>

</style>
